<template>
	<div>
		<div class="mp4-bg pb-5">
			<div class="container pt-4 pb-4">
				<div class="row">
					<div class="col-12 pt-5">
						<h2>{{ $t('message.contacts.title') }}</h2>
					</div>
				</div>
			</div>
		</div>

		<div class="container pt-5 pb-5 contacts">
			<div class="row">
				<div class="col-12 col-md-4">
					<h5 class="pb-3">
						{{ $t('message.contacts.title2') }}
					</h5>
					<p><i class="fas fa-map-marker-alt"></i> Carvoeiros, Leiria</p>
					<p><i class="fas fa-map-marker-alt"></i> Zona Industrial Casal do Cego, Leiria</p>
					<p><i class="fas fa-map-marker-alt"></i> Lavradio, Caldas da Rainha</p>
				</div>
				<div class="col-12 col-md-4">
					<h5 class="pb-3">
						{{ $t('message.contacts.title3') }}
					</h5>
					<p>
						<i class="fas fa-phone"></i> {{ $t('message.contacts.title5') }}: <a
						href="tel:+351 244 830 000">+351 244 830 000</a>
					</p>
					<p style="font-size:0.75rem;font-weight:400;margin: -15px 30px 15px 30px;">{{ $t('message.cost') }}</p>
					<p>
						<i class="fas fa-phone"></i> {{ $t('message.contacts.mobile') }}: <a
						href="tel:+351 924 114 635">+351 924 114 635</a>
					</p>
					<p style="font-size:0.75rem;font-weight:400;margin: -15px 30px 15px 30px;">{{ $t('message.cost_mobile') }}</p>
					<p>
						<i class="far fa-envelope"></i> Email: <a href="mailto:geral@lubrirent.pt"></a>geral@lubrirent.pt
					</p>
				</div>
				<div class="col-12 col-md-4">
					<h5 class="pb-3">
						{{ $t('message.contacts.title4') }}
					</h5>
					<p><i class="far fa-clock"></i> {{ $t('message.contacts.title6') }}: {{ $t('message.footer.horario1') }}</p>
					<!-- <p><i class="far fa-clock"></i> {{ $t('message.contacts.title7') }}: {{ $t('message.footer.horario2')}}</p> -->
				</div>
			</div>
		</div>

		<form @submit.prevent="submit">
			<div class="container contacts mb-5">
				<div class="row">
					<div class="col-12 pt-5 pb-5">
						<h2>{{ $t('message.contacts.title8') }}</h2>
					</div>
					<div class="col-12">
						<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="message" :class="{ 'hasError': $v.form.message.$error }">{{
											$t('message.contacts.label1')
										}}<span
											style="color:red;">*</span></label>
									<textarea v-model="form.message" class="form-control" name="message" id="message"
											  rows="9"></textarea>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="name" :class="{ 'hasError': $v.form.name.$error }">{{
											$t('message.contacts.label2')
										}}<span
											style="color:red;">*</span></label>
									<input type="text" v-model="form.name" class="form-control" id="name" name="name">
								</div>
								<div class="form-group">
									<label for="email" :class="{ 'hasError': $v.form.email.$error }">{{
											$t('message.contacts.label3')
										}}<span
											style="color:red;">*</span></label>
									<input type="email" v-model="form.email" id="email" class="form-control"
										   name="email">
								</div>

								<p>* {{ $t('message.contacts.required') }}</p>

								<button class="btn btn-primary">
									{{ $t('message.contacts.button') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div class="mp-dark-bg mt-5">
			<div class="container">
				<WhyZone></WhyZone>
			</div>
		</div>

		<div class="mp-yellow-bg">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="d-md-flex justify-content-md-between pb-3 pb-md-0">
							<div class="d-md-flex justify-content-md-start content">
								{{ $t('message.services.doubt') }}<span class="pl-2" style="color:white"> {{
									$t('message.services.contactus')
								}}!</span>
							</div>

							<div class="d-md-flex justify-content-md-end content">
								<a href="tel:+351 924 114 635"><i class="fas fa-phone mr-2"></i>+351 924 114 635</a>
								<router-link class="btn btn-primary mt-2 mt-md-0"
											 :to="{name:'contacts', params : {lang:lang}}">
									<i
										class="far fa-envelope"></i> {{ $t('message.services.contactus') }}
								</router-link>
							</div>
						</div>
						<p class="text-right" style="font-size:0.75rem;font-weight:400;margin: 15px;">{{ $t('message.cost_mobile') }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="mp-location-bg pt-5 pb-5">
			<Location></Location>
		</div>
	</div>
</template>

<script>

import axios             from 'axios';
import {email, required} from 'vuelidate/lib/validators';
import json              from '../../website-data.json';
import Location          from '../components/Location';
import WhyZone          from '../components/WhyZone';

export default {
	name       : 'ServicesPage',
	components : {
		Location,
		WhyZone,
	},
	data       : function () {
		return {
			json : json,
			lang : this.$route.params.lang,
			form : {
				name    : '',
				email   : '',
				message : '',
			}
		};
	},
	computed   : {
		rules() {
			return {
				name    : { required },
				email   : { required, email },
				message : { required }
			};
		},
	},
	methods    : {
		submit(e) {
			e.preventDefault();
			this.$v.form.$touch();
			if (this.$v.form.$error) {
				return;
			}
			let vm = this;

			axios.post(`${process.env.VUE_APP_RESERVATION_URL}/contact`, vm.form, {
					headers : {
						'Content-Type'                : 'application/json',
						'Access-Control-Allow-Origin' : '*',
						'Accept'                      : 'application/json',
					}
				})
				.then(function (response) {
					let text = vm.lang === 'pt' ? 'Pedido de contacto enviado com sucesso!' : 'Message sent successfully!';
					vm.$swal.fire({
						icon              : 'success',
						text              : text,
						type              : 'success',
						confirmButtonText : '',
						showConfirmButton : false,
						allowOutsideClick : false,
						timer             : 5000,
					});

					e.target.reset();
				})
				.catch(function (error) {
					vm.errors      = ['Error. Something happened.'];
					vm.loadSpinner = false;
					vm.disabled    = false;
				});
		}
	},
	validations() {
		return {
			form : this.rules
		};
	},
};
</script>
